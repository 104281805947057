import Header from "./components/Header";
import './assets/general.scss';
import Jumbotron from "./components/Jumbotron";
import IndexMain from "./components/IndexMain";
import WishList from "./components/WishList";
import Media from "./components/Media";
import AboutUs from "./components/AboutUs";
import MobileGames from "./components/MobileGames";
import Footer from "./components/Footer";

function App() {
    return (
        <>
            <Header />
            <Jumbotron />
            <IndexMain />
            <Media />
            <AboutUs />
            <MobileGames />
            <Footer />
        </>
    );
}

export default App;

import React from 'react';
import '../assets/media.scss';
import WishList from './WishList';

export default function Media() {
    return (
        <section id="media" className="container-fluid media-section p-5">
            <h3 className="text-center">Media</h3>
            <div className="video-container">
                <div className="video-area">
                    <iframe
                        width="1280"
                        height="720"
                        src="https://www.youtube.com/embed/k1e3eAONlqk"
                        title="Rogue Ottoman Alpha Trailer - WISHLIST on STEAM!"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    ></iframe>
                </div>
            </div>
            <div className="images-area">
                <div
                    className="image"
                    style={{
                        backgroundImage: 'url(/images/rogue-tower-1.png)',
                    }}
                ></div>
                <div
                    className="image"
                    style={{
                        backgroundImage: 'url(/images/rogue-tower-2.png)',
                    }}
                ></div>
                <div
                    className="image"
                    style={{
                        backgroundImage: 'url(/images/rogue-gameplay-3.png)',
                    }}
                ></div>
                <div
                    className="image"
                    style={{
                        backgroundImage: 'url(/images/rogue-gameplay-4.png)',
                    }}
                ></div>
                <div
                    className="image"
                    style={{
                        backgroundImage: 'url(/images/rogue-gameplay-5.png)',
                    }}
                ></div>
                <div
                    className="image"
                    style={{
                        backgroundImage: 'url(/images/rogue-gameplay-6.png)',
                    }}
                ></div>
            </div>
            <WishList />
        </section>
    );
}

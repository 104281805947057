import React from 'react';
import '../assets/mobile-games.scss';

export default function MobileGames() {
    return (
        <div id='mobile-games'>
            <div className="mobileGames">
                <div className="container">
                    <h3 className="title">OUR MOBILE GAMES</h3>
                    <div className="images">
                        <div className="image">
                            <img src="/images/rogue-mobile-game-1.png" alt="" />
                        </div>
                        <div className="image">
                            <img src="/images/rogue-mobile-game-2.png" alt="" />
                        </div>
                        <div className="image">
                            <img src="/images/rogue-mobile-game-3.png" alt="" />
                        </div>
                        <div className="image">
                            <img src="/images/rogue-mobile-game-4.png" alt="" />
                        </div>
                        <div className="image">
                            <img src="/images/rogue-mobile-game-5.png" alt="" />
                        </div>
                        <div className="image">
                            <img src="/images/rogue-mobile-game-6.png" alt="" />
                        </div>
                    </div>
                    <h6 className='top-game'>Tap games to play!</h6>
                    <div className="games">
                        <a href="https://apps.apple.com/tr/app/vending-guy/id1616477824?l=tr" target='_blank'>
                            <img src="/images/vending-guy.png" alt="" />
                            <span>Vending Guy</span>
                        </a>
                        <a href="https://apps.apple.com/tr/app/flower-lover-3d/id1638705846?l=tr" target='_blank'>
                            <img src="/images/flower-lower.png" alt="" />
                            <span>Flower Lover</span>
                        </a>
                        <a href="https://apps.apple.com/tr/app/tourism-idle-3d/id1633251487?l=tr" target='_blank'>
                            <img src="/images/tourism-idle.png" alt="" />
                            <span>Tourism Idle</span>
                        </a>
                        <a href="https://apps.apple.com/ae/app/my-long-train/id1662454825" target='_blank'>
                            <img src="/images/my-long-train.png" alt="" />
                            <span>My Long Train</span>
                        </a>
                        <a href="https://apps.apple.com/us/app/chief-town-3d/id1602233461" target='_blank'>
                            <img src="/images/chief-town.png" alt="" />
                            <span>Chief Town</span>
                        </a>
                        <a href="https://apps.apple.com/tr/app/panzer-crawler/id6443539680?l=tr" target='_blank'>
                            <img src="/images/panzer-crawler.png" alt="" />
                            <span>Panzer Crawler</span>
                        </a>
                        <a href="https://apps.apple.com/tr/app/dice-fever/id6444025154?l=tr" target='_blank'>
                            <img src="/images/dice-fever.png" alt="" />
                            <span>Dice Fever</span>
                        </a>
                        <a href="https://apps.apple.com/tr/app/merge-battle-shooting/id6444953835?l=tr" target='_blank'>
                            <img src="/images/shoting-idle.png" alt="" />
                            <span>Shooting Idle</span>
                        </a>
                        <a href="https://apps.apple.com/tr/app/brake-or-not/id1645529280?l=tr" target='_blank'>
                            <img src="/images/brake-or-not.png" alt="" />
                            <span>Brake or not</span>
                        </a>
                        <a href="#">
                            <img src="/images/wastedland.png" alt="" />
                            <span>Wastedland</span>
                        </a>
                        <a href="https://apps.apple.com/tr/app/island-commander-3d/id1629644325?l=tr" target='_blank'>
                            <img src="/images/island-army.png" alt="" />
                            <span>Island Army</span>
                        </a>
                        <a href="https://apps.apple.com/tr/app/vitamin-rush/id1613130142?l=tr" target='_blank'>
                            <img src="/images/vitamin-bar.png" alt="" />
                            <span>Vitamin Rush</span>
                        </a>
                        <a href="https://apps.apple.com/tr/app/payload-defender/id1606883437?l=tr" target='_blank'>
                            <img src="/images/payload-defender.png" alt="" />
                            <span>Payload Defender</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

import React from 'react'
import '../assets/aboutus.scss';

export default function AboutUs() {
  return (
    <section id='about-us' className=''>
        <h3 className='text-center section-head'>ABOUT US</h3>
        <div className="container aboutus-section">
            <h4>DİMİTEK</h4>
            <p className='mb-5'>Dimitek is a software company targets video games based in Ankara, Turkey.</p>
            <p>When we was studying at university in Ankara; as a few young people who were curious about video games, we set up a team and started to work to achieve our dreams. Our efforts, which started with mobile games, are now continuing as pc / console.</p>
            <div className="linkedIn-section">
                <img src="/images/rogue-linkedin.png" alt="" />
                <div className="row w-100">
                    <div className="col-md-3">
                        <a href="https://www.linkedin.com/in/hamza-kontaz-530455181/" className="linkedin-button" target='blank'>Hamza Kontaz</a>
                        <h6 className='job-title'>Developer & Game Designer</h6>
                    </div>
                    <div className="col-md-3">
                        <a href="https://www.linkedin.com/in/r2detta/" className="linkedin-button" target='blank'>Furkan Yavuz</a>
                        <h6 className='job-title'>Animator & Character Designer</h6>
                    </div>
                    <div className="col-md-3">
                        <a href="https://www.linkedin.com/in/g%C3%B6kt%C3%BCrk-cindoruk-4a9a191a3/" className="linkedin-button" target='blank'>Göktürk Cindoruk</a>
                        <h6 className='job-title'>Environment Designer</h6>
                    </div>
                    <div className="col-md-3">
                        <a href="https://www.linkedin.com/in/furkanedikli/" className="linkedin-button" target='blank'>Furkan Edikli</a>
                        <h6 className='job-title'>Community & Game Designer</h6>
                    </div>
                </div>
            </div>
            <div className='mail-section'>
                <a href="mailto:dimitekgames@gmail.com">dimitekgames@gmail.com</a>
                <img src="/images/mail-icon.png" alt="" />
                <a href="mailto:info@dimitek.net">info@dimitek.net</a>
            </div>
        </div>
    </section>
  )
}

import { useState } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';

function Header(args: any) {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <div className='navbar-pink'>
            <Navbar className="container" {...args} expand="xxl">
                <NavbarBrand href="/">
                    <img src="/images/logo.png" alt="" />
                </NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="me-auto" navbar>
                        <NavItem>
                            <NavLink href="/#indexMain" className="text-bold">
                                ROGUE OTTOMAN
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="https://store.steampowered.com/app/2324380/Demons_Infernalize/" target='blank'>DEMONS INFERNALIZE</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="/#mobile-games">MOBILE GAMES</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="/#about-us">CONTACT</NavLink>
                        </NavItem>
                    </Nav>
                    <a className='social-buttons' href="https://www.youtube.com/@dimitekgames" target='_blank'>YOUTUBE</a>
                    <a className='social-buttons' href="https://discord.com/invite/54SZQfwwZy" target='_blank'>DISCORD</a>
                    <a className='social-buttons' href="https://twitter.com/dimitekgames" target='_blank'>TWITTER</a>
                </Collapse>
            </Navbar>
        </div>
    );
}

export default Header;

import '../assets/wishlist.scss';

export default function WishList() {
    return (
        <>
            <div className='wishlist'>
                {/* <a href="https://store.steampowered.com/app/2639360/Rogue_Ottoman/" className="wishlist-btn">
                    <img src="/images/wishlist.png" alt="" />
                </a> */}
                <iframe src="https://store.steampowered.com/widget/2639360/" frameBorder="0" width="646" height="190"></iframe>
            </div>
        </>
    );
}

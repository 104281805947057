import '../assets/index.scss';
import WishList from './WishList';

export default function IndexMain() {
    return (
        <>
            <div id='indexMain' className="rogue-ottoman-discord">
                <div className="container" style={{ width: '100%' }}>
                    <div className="row">
                        <div className="col-md-5 image-area">
                            <img
                                src={require('../assets/images/steam-capsule-vertical.png')}
                                alt=""
                            />
                        </div>
                        <div className="col-md-7 content-area">
                            <h4>Ottoman, Steampunk, Magic, Roguelite</h4>
                            <p>
                                Rogue Ottoman is a{' '}
                                <span>steampunk-Ottoman</span> themed{' '}
                                <span>action rogue-lite</span> game where you
                                play a female protagonist to save the dynasty
                                from collapsing. You'll explore a sprawling
                                battlefield through Istanbul. Use your swords
                                and attack with your special ability. No
                                checkpoints.{' '}
                                <span style={{ display: 'block' }}>
                                    Die, upgrade and repeat!
                                </span>
                            </p>
                            <a
                                href="https://store.steampowered.com/app/2639360/Rogue_Ottoman/"
                                className="red-link"
                            >
                                Learn more
                            </a>
                            <a
                                href="https://discord.com/invite/54SZQfwwZy"
                                target="blank"
                                className="discord-btn"
                            >
                                <img src="/images/discord.png" alt="" />
                                JOIN THE DISCORD COMMUNITY
                            </a>
                            <WishList />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


import React from 'react';
import '../assets/index.scss';

export default function Jumbotron() {
    return (
        <>
            <section className="rouge-ottoman-tower">
                <div className="container">
                    <img className='rogue-logo' src="/images/rogue-ottoman-logotype.png" alt="" />
                </div>
            </section>
            <section className="socials">
                <div className="container">
                    {/* <h3 className="text-center">Available on 2024</h3> */}
                    <div className="social-medias">
                        <a href="https://store.steampowered.com/app/2639360/Rogue_Ottoman/" className="social-media-button" style={{backgroundColor: '#2A475E', boxShadow: '0px 2px 0px 0px #2A475E'}} target='blank'>
                            STEAM
                        </a>
                        <a href="#" className="social-media-button" style={{backgroundColor: '#037A0D', boxShadow: '0px 2px 0px 0px #037A0D'}} target='blank'>
                            XBOX
                        </a>
                        <a href="https://gamejolt.com/@Dimitek" className="social-media-button" style={{backgroundColor: '#2F7F6F', boxShadow: '0px 2px 0px 0px #2F7F6F'}} target='blank'>
                            GAMEJOLT
                        </a>
                        <a href="https://zzkontaz.itch.io/" className="social-media-button" style={{backgroundColor: '#FF254D', boxShadow: '0px 2px 0px 0px #FF254D'}} target='blank'>
                            ITCH.IO
                        </a>
                    </div>
                </div>
            </section>
        </>
    );
}

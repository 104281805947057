import React from 'react'
import '../assets/footer.scss'

export default function Footer() {
  return (
      <footer>
          <div className="container">
              <div className="row">
                  <div className="col-md-3">
                      <a href="#" className="logo">
                          <img src="/images/dimitek-type-1.png" alt="" />
                      </a>
                  </div>
                  <div className="col-md-9 footer-content">
                      <h6>Dimitek Yazılım Teknolojileri Anonim Şirketi</h6>
                      <p>
                          Teknopark Ankara Nar Kuluçka Merkezi -
                          Yenimahalle/Ankara
                      </p>
                      <a href="/privacy">Privacy Policy</a>
                      <a href="#about-us">Contact Us</a>
                  </div>
              </div>
              <section className="socials" style={{ marginBottom: 0 }}>
                  <div className="container">
                      <div className="social-medias">
                          <a
                              href="https://store.steampowered.com/app/2639360/Rogue_Ottoman/"
                              className="social-media-button"
                              style={{
                                  backgroundColor: '#2A475E',
                                  boxShadow: '0px 2px 0px 0px #2A475E',
                              }}
                              target='blank'
                          >
                              STEAM
                          </a>
                          <a
                              href="#"
                              className="social-media-button"
                              style={{
                                  backgroundColor: '#037A0D',
                                  boxShadow: '0px 2px 0px 0px #037A0D',
                              }}
                              target='blank'
                          >
                              XBOX
                          </a>
                          <a
                              href="https://gamejolt.com/@Dimitek"
                              className="social-media-button"
                              style={{
                                  backgroundColor: '#2F7F6F',
                                  boxShadow: '0px 2px 0px 0px #2F7F6F',
                              }}
                              target='blank'
                          >
                              GAMEJOLT
                          </a>
                          <a
                              href="https://zzkontaz.itch.io/"
                              className="social-media-button"
                              style={{
                                  backgroundColor: '#FF254D',
                                  boxShadow: '0px 2px 0px 0px #FF254D',
                              }}
                              target='blank'
                          >
                              ITCH.IO
                          </a>
                      </div>
                  </div>
              </section>
          </div>
      </footer>
  );
}
